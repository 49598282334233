import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import '../../App.css';
import {logout} from '../../api/auth';
import FirestoreManager from '../../api/firebaseManager';
import logoImage from '../../assets/images/forest-logo.png';
import {useAuth} from '../../hooks/use-auth';
import {useBill} from '../../hooks/use-bill';
import {usePlaid} from '../../hooks/use-plaid';
import {PlaidLinkModal} from '../Plaid/PlaidLinker';
import {Modal} from '../modal/modal';

function Navbar({user, children}) {
  const {
    state: {profile},
    getProfile,
  } = useAuth();
  const {fetchInitialFromDB} = usePlaid();
  const {fetchInitialBill} = useBill();

  const admin = !!profile.admin;

  useEffect(() => {
    if (user?.uid) {
      // ONLY PULL CURRENT USER INFO IF NOT ADMIN
      if (!admin) {
        fetchInitialFromDB(user.uid);
        fetchInitialBill(user.uid);
      }
      getProfile(user.uid);
    }
  }, [user?.uid]);

  return (
    <div>
      <AppBar position="static" sx={{backgroundColor: '#f6f6f6'}}>
        <div className="flex-row justify-between align-center">
          <img
            alt="Forest Connect Logo"
            className="logo-image"
            style={{width: '200px', height: '58px'}}
            src={logoImage}
          />
          {user ? (
            admin ? (
              <AdminBar user={user} />
            ) : (
              <UserBar user={user} />
            )
          ) : null}
        </div>
      </AppBar>
      {children || <Outlet />}
    </div>
  );
}

const AdminBar = ({user}) => {
  const {reset} = useAuth();
  const {resetPlaid} = usePlaid();
  const navigate = useNavigate();

  const logOut = async () => {
    await logout();
    reset();
    resetPlaid();
    navigate('/');
  };

  return (
    <div className="flex-row">
      <button
        className="gray-button"
        onClick={() => {
          navigate('/admin/user/dashboard');
        }}>
        Users
      </button>
      <button
        className="gray-button"
        onClick={() => {
          navigate('/admin/client/dashboard');
        }}>
        Clients
      </button>
      <button className="gray-button" onClick={logOut}>
        Log out
      </button>
    </div>
  );
};

const UserBar = ({user}) => {
  const navigate = useNavigate();

  const {
    state: {profile, client},
  } = useAuth();

  const [connect, setConnect] = useState(false);

  const logOut = async () => {
    await logout();
    navigate('/');
  };

  return (
    <>
      <div className="flex-row">
        {client?.bill && (
          <button
            style={{
              zIndex: 1,
              backgroundColor: '#ff5605',
              height: '30px',
              border: '0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px 32px',
              margin: '0px 8px',
              fontSize: 12,
              border: '1.2px solid #c5c7c6',
            }}
            onClick={() => {
              setConnect(!connect);
            }}>
            Connect Bill.com
          </button>
        )}
        {/* {client?.plaid && (
          <PlaidLinker uid={user.uid}>
            <div
              style={{
                right: 30,
                zIndex: 1,
                backgroundColor: '#111111',
                width: '150px',
                height: '30px',
                border: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0px 8px',
                border: '1.2px solid #c5c7c6',
              }}>
              <p
                style={{
                  color: 'white',
                  fontSize: 12,
                  padding: '0px',
                  alignSelf: 'center',
                }}>
                Connect Bank Account
              </p>
            </div>
          </PlaidLinker>
        )} */}

        {client?.plaid && <PlaidLinkModal user={user} />}

        <button
          style={{
            zIndex: 1,
            backgroundColor: '#c5c7c6',
            height: '30px',
            border: '1.2px solid black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 32px',
            margin: '0px 8px',
            fontSize: 12,
          }}
          onClick={logOut}>
          Log out
        </button>
      </div>
      {connect && <BillModal active={connect} setActive={setConnect} />}
    </>
  );
};

const BillModal = ({active, setActive}) => {
  const {
    state: {profile},
  } = useAuth();

  const [index, setIndex] = useState(0);
  const [form, setForm] = useState({
    uid: profile.uid,
    customerID: profile.customerID,
    userName: '',
    password: '',
    entity_name: '',
    company: '',
    accounts_payable: '',
    accounts_receivable: '',
    vendor_credits: '',
    credit_memo: '',
    organizations: [],
    error: null,
  });

  return (
    <Modal active={active} setActive={setActive} disabled_dismiss={true}>
      <div className="box flex-column">
        {index === 0 && (
          <ConnectBill
            form={form}
            setForm={setForm}
            setIndex={setIndex}
            setActive={setActive}
          />
        )}
        {index === 1 && (
          <DefineAccounts
            form={form}
            setForm={setForm}
            setIndex={setIndex}
            setActive={setActive}
          />
        )}
      </div>
    </Modal>
  );
};

const ConnectBill = ({form, setForm, setIndex, setActive}) => {
  const {
    state: {connection_ids},
  } = useBill();

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <IconButton
        aria-label="close"
        onClick={() => setActive(false)}
        style={{position: 'absolute', top: 8, right: 8}}>
        <CloseIcon />
      </IconButton>

      <Typography sx={{textAlign: 'center', fontSize: '22px'}}>
        Connecting to Bill.com
      </Typography>
      <Typography>
        Enter the following information to receive transactions from Bill.com.
      </Typography>
      <br />
      <Typography>
        Contact Support@forestsystems.com if you have any questions.
      </Typography>
      <div className="box">
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Forest Entity Name:</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="entity_name"
                label="Entity Name"
                placeholder="entity_name"
                value={form.entity_name}
                type="text"
                onChange={evt =>
                  setForm({...form, entity_name: evt.target.value})
                }
                variant="filled"
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Typography>
          To view the name of the Forest database you are trying to connect, log
          on to Forest View and Users | Administrative. The name is displayed
          under Select a database.
        </Typography>
        <br />
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Bill Company Name:</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="company"
                label="Company"
                placeholder="Company"
                value={form.company}
                type="text"
                onChange={evt => setForm({...form, company: evt.target.value})}
                variant="filled"
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Typography>
          This is the company name within Bill.com. It is usually displayed on
          the top left of the screen when entering payable and receivable
          transactions.
        </Typography>
        <br />
        <br />
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Sync token Name:</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="userName"
                label="Username"
                placeholder="UserName"
                value={form.userName}
                type="text"
                onChange={evt => setForm({...form, userName: evt.target.value})}
                variant="filled"
              />
            </FormControl>
          </Grid>
          <br />
          <Grid container spacing={0}>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <Typography>Sync Token Password:</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="password"
                  label="Password"
                  placeholder="Password"
                  value={form.password}
                  type="password"
                  onChange={evt =>
                    setForm({...form, password: evt.target.value})
                  }
                  variant="filled"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Typography>
          Tokens can be found and created from within Bill.com. Go to Settings |
          Sync & Integrations | Tokens
        </Typography>
        <br />
        <Typography>
          Token passwords are displayed only once. Delete and recreate tokens to
          generate a new password.
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Button
          onClick={() => setActive(false)}
          style={{
            zIndex: 1,
            backgroundColor: '#c5c7c6',
            height: '30px',
            border: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 32px',
            margin: '16px 32px 0px 32px',
            color: 'black',
          }}>
          Close
        </Button>
        <Button
          disabled={loading}
          style={{
            zIndex: 1,
            backgroundColor: '#c5c7c6',
            height: '30px',
            border: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 32px',
            margin: '16px 32px 0px 32px',
            color: 'black',
          }}
          onClick={async () => {
            const {userName, password, entity_name, company} = form;
            if (!userName || !password || !entity_name || !company) {
              window.alert('Fill out all fields to continue.');
              return;
            }

            const exists = connection_ids.find(id => id === userName);
            if (exists) {
              window.alert('Connection already exists.');
              return;
            }

            setLoading(true);

            const response = await FirestoreManager.getBillOrganizations({
              userName,
              password,
            });

            const {success, error, data} = response;
            if (success) {
              const {response_status, response_message, response_data} = data;
              if (response_status === 0) {
                setForm({...form, organizations: response_data});
                setIndex(1);
              } else {
                const {error_message} = response_data;
                window.alert(
                  error_message ||
                    'Something went wrong. Please try again or reach out to Support@forestsystems.com.',
                );
              }
            }
            if (error) {
              window.alert(
                'Something went wrong. Please try again or reach out to Support@forestsystems.com.',
              );
            }
            setLoading(false);
          }}>
          Next
        </Button>
      </div>
    </div>
  );
};

const DefineAccounts = ({form, setForm, setIndex, setActive}) => {
  const {createConnection} = useBill();

  return (
    <div>
      <IconButton
        aria-label="close"
        onClick={() => setActive(false)}
        style={{position: 'absolute', top: 8, right: 8}}>
        <CloseIcon />
      </IconButton>

      <Typography sx={{textAlign: 'center', fontSize: '22px'}}>
        Connecting to Bill.com
      </Typography>
      <br />
      <Typography>
        The Forest and Bill.com integration requires 4 journal accounts in
        Forest Enter. The exact names of these accounts are identified here for
        the integration to balance and create double entry transactions with
        Bill.com data.
      </Typography>
      <div className="box">
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Accounts Payable:</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="accounts_payable"
                label="Accounts Payable"
                placeholder="accounts_payable"
                value={form.accounts_payable}
                type="text"
                onChange={evt =>
                  setForm({...form, accounts_payable: evt.target.value})
                }
                variant="filled"
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Typography>
          Enter the Forest account name for Accounts Payable. This account is
          used to balance bill, payment out, and vendor credit data from
          Bill.com
        </Typography>
        <br />
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Accounts Receivable:</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="accounts_receivable"
                label="Accounts Receivable"
                placeholder="accounts_receivable"
                value={form.accounts_receivable}
                type="text"
                onChange={evt =>
                  setForm({...form, accounts_receivable: evt.target.value})
                }
                variant="filled"
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Typography>
          Enter the Forest account name for Accounts Receivable. This account is
          used to balance invoice, payment in, and credit memo data from
          Bill.com
        </Typography>
        <br />
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Vendor Credits:</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="vendor_credits"
                label="Vendor Credits"
                placeholder="vendor_credits"
                value={form.vendor_credits}
                type="text"
                onChange={evt =>
                  setForm({...form, vendor_credits: evt.target.value})
                }
                variant="filled"
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Typography>
          Enter the Forest account name for Vendor Credits.
        </Typography>
        <br />
        <Grid container spacing={0}>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <Typography>Credit Memo:</Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                id="credit_memo"
                label="Credit Memo"
                placeholder="credit_memo"
                value={form.credit_memo}
                type="text"
                onChange={evt =>
                  setForm({...form, credit_memo: evt.target.value})
                }
                variant="filled"
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Typography>Enter the Forest account name for Credit Memos.</Typography>
        <br />
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Button
          onClick={() => setIndex(0)} // nav back to the previous modal
          style={{
            zIndex: 1,
            backgroundColor: '#c5c7c6',
            height: '30px',
            border: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 32px',
            margin: '16px 32px 0px 32px',
            color: 'black',
          }}>
          Back
        </Button>
        <Button
          style={{
            zIndex: 1,
            backgroundColor: '#c5c7c6',
            height: '30px',
            border: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 32px',
            margin: '16px 32px 0px 32px',
            color: 'black',
          }}
          onClick={async () => {
            const {
              accounts_payable,
              accounts_receivable,
              vendor_credits,
              credit_memo,
            } = form;

            if (
              !accounts_payable ||
              !accounts_receivable ||
              !vendor_credits ||
              !credit_memo
            ) {
              window.alert('Fill out all fields to continue.');
              return;
            }

            const {success, error} = await createConnection(form);
            if (success) {
              setIndex(0);
              setActive(false);
              window.alert('Bill.com connection was successful.');
            }

            if (error) {
              window.alert(
                'Something went wrong. Please try again or reach out to Support@forestsystems.com.',
              );
            }
          }}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Navbar;
