import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAuth} from '../../hooks/use-auth';
import {useBill} from '../../hooks/use-bill';
import {usePlaid} from '../../hooks/use-plaid';
import '../../styles/plaid.css';

const UserAccounts = ({user}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {
    state: {users},
    deleteUser,
  } = useAuth();
  const {
    state: {all_accounts, user_accounts, user_institutions, all_institutions},
    getUserAccounts,
    getUserInstitutions,
    removeUserInstitutions,
  } = usePlaid();
  const {
    state: {user_connections, connections},
    getUserConnections,
    removeUserConnections,
  } = useBill();

  const profile = users?.[id] ?? {};
  const {fullName, customerID, emailAddress} = profile;

  const accounts = user_accounts?.[id] ?? [];
  const cons = user_connections?.[id] ?? [];

  useEffect(() => {
    if (id && user_accounts?.[id] === undefined) {
      getUserAccounts(id);
    }
    if (id && user_institutions?.[id] === undefined) {
      getUserInstitutions(id);
    }
    if (id && user_connections[id] === undefined) {
      getUserConnections(id);
    }
  }, []);

  return (
    <div className="page-container">
      <div className="flex-row justify-between align-center">
        <p style={{fontWeight: 'bold', fontSize: '24px'}}>
          User: {emailAddress} - All Connections
        </p>
        <button
          className="gray-button"
          onClick={() => {
            navigate(-1);
          }}>
          Done
        </button>
      </div>
      <div className="flex-row justify-between">
        <table>
          <thead>
            <tr>
              <th>Institution</th>
              <th>Entity</th>
              <th>Account Name</th>
              <th>Account Type</th>
              <th>Account Number Ending</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map(id => {
              const {
                name,
                subtype,
                mask,
                disable,
                entity,
                error,
                access_token,
              } = all_accounts[id];
              const inst = all_institutions?.[access_token] ?? {};
              const {name: inst_name} = inst;

              return (
                <tr key={id}>
                  <td>{inst_name}</td>
                  <td>{entity}</td>
                  <td>{name}</td>
                  <td>{subtype}</td>
                  <td>{mask}</td>
                  <td>{error || 'Active'}</td>
                </tr>
              );
            })}
            {cons.map(id => {
              const {organizations} = connections?.[id] ?? {};

              return organizations?.map(org => {
                const {orgName} = org;
                return (
                  <tr key={id}>
                    <td>Bill.com</td>
                    <td>{orgName}</td>
                    <td>{'Forest Account'}</td>
                    <td>{null}</td>
                    <td>{'Active'}</td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
        <div>
          <button
            className="gray-button"
            onClick={async () => {
              if (
                window.confirm(
                  'Are you sure you want to delete this user? This disconnects all accounts related to the user',
                )
              ) {
                // Proceed with delete operation of accounts and connections
                console.log('Item deleted');

                // DELETE PLAID OBJECTS
                const access_tokens = user_institutions[id];
                await removeUserInstitutions(id, access_tokens);
                // DELETE BILL OBJECTS
                const connection_ids = user_connections[id];
                await removeUserConnections(id, connection_ids);
                navigate(-1);
              } else {
                // Cancel delete operation
                console.log('Delete cancelled');
              }
            }}>
            Disconnect All Accounts
          </button>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default UserAccounts;
