import {TextField} from '@mui/material';
import React, {useState} from 'react';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';
import {useNavigate} from 'react-router-dom';
import {useBill} from '../../hooks/use-bill';
import {usePlaid} from '../../hooks/use-plaid';
import '../../styles/plaid.css';

export default function Dashboard({user}) {
  const navigate = useNavigate();

  const {
    state: {institutions, linking, accountsMissingInfo, accounts},
    addMissingInfo,
  } = usePlaid();
  const {
    state: {connection_ids, connections},
  } = useBill();

  const [accountNumber, setAccountNumber] = useState('');

  return (
    <div className="page-container">
      {linking ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Spinner size={50} color={'gray'} speed={0.5} style={{top: 300}} />
        </div>
      ) : !!institutions?.length || !!connection_ids?.length ? (
        <div>
          <div style={{margin: ''}}>
            <table>
              <thead>
                <tr>
                  <th>Institution</th>
                  <th>Status</th>
                  <th>Accounts</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {institutions.map(item => {
                  return <Row key={item.item_id} item={item} />;
                })}
                {connection_ids.map(con_id => {
                  const {id, organizations} = connections?.[con_id] ?? {};

                  return (
                    <tr key={id}>
                      <td>Bill.com</td>
                      <td>{'Active'}</td>
                      <td>{organizations?.length ?? 0}</td>
                      <td>
                        <button
                          style={{color: 'blue'}}
                          onClick={() => {
                            navigate(`/connections/${id}`);
                          }}>
                          More Info
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {accountsMissingInfo?.length > 0 && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'black',
                opacity: 0.5,
                height: window.screen.height,
                width: window.screen.width,
                zIndex: 0,
              }}
            />
          )}
          {accountsMissingInfo?.length > 0 &&
            accountsMissingInfo.map(account => (
              <div
                style={{
                  justifyContent: 'center',
                  width: window.screen.width,
                  height: window.screen.height,
                  display: 'flex',
                }}>
                <div
                  style={{
                    height: 300,
                    width: 400,
                    marginLeft: -150,
                    alignItems: 'center',
                    opacity: 1,
                    backgroundColor: 'white',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: -200,
                  }}>
                  <h2 style={{textAlign: 'center', fontSize: 16}}>
                    <strong>Please Confirm Account Number</strong>
                  </h2>
                  <h2 style={{marginBottom: 0, textAlign: 'center'}}>
                    {account.name}
                  </h2>
                  <TextField
                    id="confirmPassword"
                    label="Confirm Account Number (Last 4 Digits)"
                    style={{
                      background: 'white',
                      width: '80%',
                      alignSelf: 'center',
                      marginTop: 50,
                    }}
                    value={accountNumber}
                    type="text"
                    onChange={evt => {
                      if (accountNumber.length < 4) {
                        setAccountNumber(evt.target.value);
                      }
                    }}
                    variant="filled"
                    placeholder="Confirm Account Number (Last 4 Digits)"
                  />
                  {user && (
                    <button
                      onClick={() => {
                        if (user.uid) {
                          addMissingInfo(
                            user.uid,
                            account,
                            accountNumber,
                            accountsMissingInfo,
                            accounts,
                          );
                          setAccountNumber('');
                        }
                      }}
                      style={{
                        zIndex: 1,
                        backgroundColor: '#316b3b',
                        width: '150px',
                        height: '40px',
                        border: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: 50,
                      }}>
                      <p
                        style={{
                          color: 'white',
                          fontSize: 14,
                          padding: '0px',
                          alignSelf: 'center',
                        }}>
                        Confirm
                      </p>
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div style={{textAlign: 'center'}}>
          <h3>Please Link Accounts</h3>
        </div>
      )}
    </div>
  );
}

const Row = ({item}) => {
  const navigate = useNavigate();

  const {name, error, accounts, item_id} = item;

  return (
    <tr key={item_id}>
      <td>{name}</td>
      <td>{error || 'Active'}</td>
      <td>{accounts}</td>
      <td>
        <button
          style={{color: 'blue'}}
          onClick={() => {
            navigate(`/accounts/${item_id}`);
          }}>
          More Info
        </button>
      </td>
    </tr>
  );
};
