import {TextField, Typography} from '@mui/material';
import {getApp} from 'firebase/app';
import {getFunctions, httpsCallable} from 'firebase/functions';
import React, {useEffect, useRef, useState} from 'react';
import {PlaidLink} from 'react-plaid-link';
import PlaidManager from '../../api/plaidManager';
import {usePlaid} from '../../hooks/use-plaid';
import {Modal} from '../modal/modal';
const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "localhost", 5001);

export const PlaidLinkModal = React.memo(({user}) => {
  const [active, setActive] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setActive(!active);
        }}
        style={{
          right: 30,
          zIndex: 1,
          backgroundColor: 'black',
          width: '168px',
          height: '30px',
          border: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0px 8px',
        }}>
        <p
          style={{
            color: 'white',
            fontSize: 14,
            padding: '0px',
            alignSelf: 'center',
            fontWeight: '400',
          }}>
          Connect Bank Account
        </p>
      </div>
      {active && (
        <PlaidLinkContent active={active} setActive={setActive} user={user} />
      )}
    </>
  );
});

const PlaidLinkContent = ({active, setActive, user}) => {
  const [entity, setEntity] = useState('');

  return (
    <Modal active={active} setActive={setActive}>
      <div className="box flex-column">
        <Typography sx={{textAlign: 'center', fontSize: '22px'}}>
          Forest Entity
        </Typography>
        <br />
        <Typography>
          Enter the Forest Entity name in the field below. This name will be
          associated with all accounts in this connection.
        </Typography>
        <br />
        <TextField
          label="Entity"
          placeholder="Entity"
          value={entity}
          type="text"
          onChange={evt => setEntity(evt.target.value)}
          variant="filled"
        />
        <br />
        <Typography>
          After the connection is made, users can change the entity name for
          specific accounts in the More Info screen. Users will need to make
          these changes if the account owner is using a "Doing Business As"
          alias, or has an account for an LLC, and has a separate Forest entity
          for those accounts.
        </Typography>
        <br />
        <Typography>
          Contact support@forestsystems.com if you have any questions
        </Typography>
        <br />
        <div className="flex-row justify-between">
          <button
            style={{
              zIndex: 1,
              backgroundColor: '#c5c7c6',
              height: '30px',
              border: '1.2px solid black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px 32px',
              margin: '0px 8px',
              fontSize: 12,
            }}
            onClick={() => {
              setActive(false);
            }}>
            Close
          </button>
          {entity && (
            <PlaidLinker
              uid={user.uid}
              entity={entity}
              onExit={() => {
                setActive(false);
              }}
              onSuccess={() => {
                setActive(false);
              }}>
              <div
                style={{
                  zIndex: 1,
                  backgroundColor: '#c5c7c6',
                  height: '30px',
                  border: '1.2px solid black',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0px 32px',
                  margin: '0px 8px',
                  fontSize: 12,
                }}>
                <p
                  style={{
                    fontSize: 12,
                    padding: '0px',
                    alignSelf: 'center',
                  }}>
                  Next
                </p>
              </div>
            </PlaidLinker>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const PlaidLinker = ({
  children,
  onSuccess,
  onExit,
  style,
  uid,
  entity,
}) => {
  const {
    state: {accounts, institutions},
    addPlaidInstitution,
    setLinking,
    addPlaidAccounts,
  } = usePlaid();

  const [token, setToken] = useState(null);
  const entityRef = useRef(entity);

  // Update the ref whenever entity changes
  useEffect(() => {
    entityRef.current = entity;
  }, [entity]);

  const getToken = async () => {
    try {
      const getToken = httpsCallable(functions, 'plaid-getLinkToken');
      const linkToken = await getToken(uid);

      if (linkToken) {
        setToken(linkToken.data);
      }
    } catch (err) {
      console.error(err);
      // TODO: Implement error handling
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  const handleSuccess = async (public_token, metadata) => {
    setLinking(true);
    try {
      if (isDuplicateAccount(metadata)) {
        window.alert(
          "It looks like you have already linked that account! Try waiting if you don't see it yet or link a different institution.",
        );
        setLinking(false);
        return;
      }
      if (onSuccess) {
        onSuccess();
      }
      const {access_token, item_id} = await PlaidManager.exchangePublicToken(
        public_token,
      );
      const itemDetails = await PlaidManager.getInstitutionWithMetaData(
        metadata.institution.institution_id,
      );
      const item = {
        uid,
        access_token,
        item_id,
        institution_id: metadata.institution.institution_id,
        ...itemDetails,
      };

      // Use the ref to get the latest entity value
      await addPlaidAccounts(access_token, uid, item_id, entityRef.current);
      await addPlaidInstitution(item_id, item, uid);
      setLinking(false);
    } catch (err) {
      console.error(err);
      setLinking(false);
    }
  };

  const isDuplicateAccount = metadata => {
    const inst_id = metadata.institution.institution_id;
    const uniqueAccount =
      !institutions.some(
        institution => institution.institution_id === inst_id,
      ) &&
      accounts.every(currentAccount =>
        metadata.accounts.every(
          newAccount =>
            currentAccount.mask !== newAccount.mask ||
            currentAccount.name !== newAccount.name,
        ),
      );
    return !uniqueAccount;
  };

  return (
    <PlaidLink
      token={token}
      style={{background: 'none', border: 'none', padding: 0}}
      onExit={onExit}
      onSuccess={handleSuccess}>
      {children ? children : <h2>Connect a bank account</h2>}
    </PlaidLink>
  );
};

export const PlaidUpdater = React.memo(
  ({children, onSuccess, uid, onExit, access_token, item_id}) => {
    const {addPlaidInstitution} = usePlaid();

    const [token, setToken] = useState(null);

    const getToken = async () => {
      try {
        if (!uid) {
          return;
        }
        const getToken = httpsCallable(functions, 'plaid-getUpdateToken');
        const data = {uid, access_token};
        const linkToken = await getToken(data);

        if (linkToken) {
          setToken(linkToken.data);
        }
      } catch (err) {
        console.error(err);
        //TODO: Implement error handling
      }
    };

    useEffect(() => {
      getToken();
    }, []);

    const handleSuccess = async () => {
      try {
        window.alert(
          'Updating your account was successful. Your transactions and balances will start updating again.',
        );
        addPlaidInstitution(item_id, {error: null});
        onSuccess();
      } catch (err) {
        console.error(err);
        //TODO: Implement proper error handling
      }
    };

    return (
      <PlaidLink
        token={token}
        style={{background: 'none', border: 'none', padding: 0}}
        onExit={onExit}
        onSuccess={handleSuccess}>
        {children ? children : <h2>Update account</h2>}
      </PlaidLink>
    );
  },
);
