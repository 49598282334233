import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAuth} from '../../hooks/use-auth';
import {useBill} from '../../hooks/use-bill';
import {usePlaid} from '../../hooks/use-plaid';
import '../../styles/plaid.css';

const ClientAccounts = ({user}) => {
  const {id} = useParams();
  const navigate = useNavigate();

  const {
    state: {all_accounts, user_accounts, all_institutions, user_institutions},
    getUserAccounts,
    getUserInstitutions,
    removeUserInstitutions,
  } = usePlaid();
  const {
    state: {user_ids, users, users_loaded, clients_loaded},
    getClients,
    getUsers,
    deleteClient,
    deleteUser,
  } = useAuth();
  const {
    state: {user_connections, connections},
    getUserConnections,
    removeUserConnections,
  } = useBill();

  const [clients, setClients] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [cons, setConnections] = useState([]);

  useEffect(() => {
    const loadClients = async () => {
      await getClients();
    };

    const loadUsers = async () => {
      await getUsers();
    };

    if (!users_loaded) {
      loadUsers();
    }
    if (!clients_loaded) {
      loadClients();
    }
  }, []);

  useEffect(() => {
    const ids = user_ids.filter(user_id => {
      const {customerID} = users[user_id];
      if (customerID === id) {
        getUserAccounts(user_id);
        getUserConnections(user_id);
        getUserInstitutions(user_id);

        return true;
      }
      return false;
    });

    setClients(ids);
  }, [user_ids]);

  useEffect(() => {
    let sum = [];
    clients.forEach(user_id => {
      const ids = user_accounts[user_id];
      sum = [...sum, ...(ids || [])];
    });
    setAccounts(sum);
  }, [user_accounts, clients]);

  useEffect(() => {
    let sum = [];
    clients.forEach(user_id => {
      const con_ids = user_connections[user_id];
      con_ids?.forEach(id => {
        const {organizations} = connections[id];
        sum = [...sum, ...(organizations || [])];
      });
    });
    setConnections(sum);
  }, [user_connections, clients]);

  return (
    <div className="page-container">
      <div className="flex-row justify-between align-center">
        <p style={{fontWeight: 'bold', fontSize: '24px'}}>
          Client: {id} - All Connections
        </p>
        <button
          className="gray-button"
          onClick={() => {
            navigate(-1);
          }}>
          Done
        </button>
      </div>
      <div className="flex-row justify-between">
        <table>
          <thead>
            <tr>
              <th>Institution</th>
              <th>Entity</th>
              <th>Account Name</th>
              <th>Account Type</th>
              <th>Account Number Ending</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map(id => {
              const {
                name,
                subtype,
                mask,
                disable,
                entity,
                error,
                access_token,
              } = all_accounts[id];
              const inst = all_institutions?.[access_token] ?? {};
              const {name: inst_name} = inst;

              return (
                <tr key={access_token}>
                  <td>{inst_name}</td>
                  <td>{entity}</td>
                  <td>{name}</td>
                  <td>{subtype}</td>
                  <td>{mask}</td>
                  <td>{error || 'Active'}</td>
                </tr>
              );
            })}
            {cons.map(con => {
              const {orgName, orgId} = con;

              return (
                <tr key={orgId}>
                  <td>Bill.com</td>
                  <td>{orgName}</td>
                  <td>{'Forest Account'}</td>
                  <td>{null}</td>
                  <td>{'Active'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <button
            className="gray-button"
            onClick={async () => {
              if (
                window.confirm('Are you sure you want to delete this client?')
              ) {
                // Proceed with delete operation
                console.log('Item deleted');

                // REMOVE CLIENT FROM CLIENT LIST
                await deleteClient(id);

                // FIND USERS
                const removed_users = user_ids.filter(user_id => {
                  const {customerID} = users?.[user_id] ?? {};
                  return customerID === id;
                });

                await Promise.all(
                  removed_users.map(async user_id => {
                    const {emailAddress} = users?.[user_id] ?? {};
                    // DELETE USER OBJECT
                    await deleteUser(user_id, emailAddress);
                    // DELETE PLAID OBJECTS
                    const access_tokens = user_institutions[user_id];
                    await removeUserInstitutions(user_id, access_tokens);
                    // DELETE BILL OBJECTS
                    const connection_ids = user_connections[user_id];
                    await removeUserConnections(user_id, connection_ids);
                  }),
                );
                navigate(-1);
              } else {
                // Cancel delete operation
                console.log('Delete cancelled');
              }
            }}>
            Disconnect Accounts and Delete Client
          </button>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default ClientAccounts;
